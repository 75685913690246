import $ from 'jquery';
window.jQuery = $;

import 'popper.js';
import Cookies from 'js-cookie';
window.Cookies = Cookies;

//import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';

//import './ekko-lightbox.js';
//import ekkoLightbox from 'ekko-lightbox';
//import 'ekko-lightbox/dist/ekko-lightbox';

import bgset from 'lazysizes/plugins/bgset/ls.bgset.min'; // Used for backgrounds.
import lazySizes from 'lazysizes';

window.fitWithin = function(objWidth, objHeight, targWidth, targHeight){
  var imgWidth, imgHeight;
	var theImgRatio = objWidth/objHeight;
	var theScrRatio = targWidth/targHeight;
	if (theImgRatio < theScrRatio) {
		imgHeight = targHeight;
		imgWidth = Math.round(theImgRatio * targHeight);
	} else {
		imgWidth = targWidth;
		imgHeight = Math.round(targWidth / theImgRatio);
	}
	var tmpArr = new Array(2);
	tmpArr['width'] = imgWidth;
	tmpArr['height'] = imgHeight;
	return tmpArr;
}

window.isHome = false;
window.winWd = 0;
window.winHt = 0;
window.showingLightbox = false;

window.$responsiveElem = {};
window.responsiveFontSize = 10;

window.winResize = function(){
  window.winWd = jQuery(window).width();
  window.winHt = jQuery(window).height();
  if (isHome) {
    jQuery('.section.now-showing .flag-view').css({ 'height': '', 'width': '' });
    var sectionHt = jQuery('.section.now-showing').height();
    var tickerHt = jQuery('.section.now-showing .ticker-tape').outerHeight();
    var promptHt = jQuery('.section.now-showing .design-prompt').outerHeight();
    var viewHt = (sectionHt - (tickerHt + promptHt));
    var imgDim = window.fitWithin(200, 300, window.winWd, viewHt);
    jQuery('.section.now-showing .flag-view').css({ 'width': imgDim['width']+"px", 'height': imgDim['height']+"px" });
  }
  window.responsiveFontSize = parseFloat(window.$responsiveElem.css('font-size'));

  window.modalPadding = 30;
  if (window.responsiveFontSize > 10) {
    window.modalPadding = 60;
  }

  if (window.showingLightbox) {
    window.resizeLightboxImage();
  }
}

window.resizeLightboxImage = function(){
  var $img = $lightbox.find('img');
  var imgWidth = $img.data('width');
  var imgHeight = $img.data('height');
  var imgDim = window.fitWithin(imgWidth, imgHeight, window.winWd - window.modalPadding, window.winHt - window.modalPadding);
  var css = {
    'width': imgDim['width']+'px',
    'height': imgDim['height']+'px'
  };
  window.$lightbox.find('.modal-dialog').css(css);
}

//jQuery(document).ready(function(){   // old jquery ready method...
window.setupStuff = function(){

  window.$lightbox = jQuery('#lightbox');

  jQuery('body').prepend('<div class="responsive-test"></div>');
  window.$responsiveElem = $('body > .responsive-test');

  jQuery('body').on('click', 'header a', function(){
    jQuery('header #siteNavbar').collapse("hide");
  });
  window.isHome = jQuery('body').hasClass('home');
  jQuery(window).on('resize', function(){
    window.winResize();
  });
  window.winResize();
  setTimeout(window.winResize, 500);

  $('a[href^="#"].smooth-scroll').on('click', function(event) {
      var target = $(this.getAttribute('href'));
      if( target.length ) {
          event.preventDefault();
          $('html, body').stop().animate({
              scrollTop: target.offset().top
          }, 1000);
      }
  });

  // jQuery(document).on('click', '[data-toggle="lightbox"]', function(event) {
  //   event.preventDefault();
  //   jQuery(this).ekkoLightbox();
  // });




  jQuery('[data-target="#lightbox"]').on('click', function(event) {
      event.preventDefault();

      var $img = jQuery(this).find('img');
      var src = jQuery(this).attr('href');
      var alt = $img.attr('alt');
      var imgWidth = $img.data('width');
      var imgHeight = $img.data('height');

      window.$lightbox.find('.modal-body').html(
        '<div class="image-wrap" style="padding-top: ' + ((imgHeight/imgWidth)*100) + '%">\
          <img class="lazyload" data-src="'+ src +'" alt="' + alt + '" data-width="' + imgWidth + '" data-height="' + imgHeight + '" />\
          <span class="img-loader"></span>\
        </div>'
      );

      window.resizeLightboxImage();

      window.$lightbox.find('.close').addClass('hidden');
  });

  $lightbox.on('shown.bs.modal', function (e) {
      window.showingLightbox = true;
      //window.$lightbox.find('.modal-dialog').css({'width': window.winWd - window.modalPadding });
      window.$lightbox.find('.close').removeClass('hidden');
  });

  $lightbox.on('hide.bs.modal', function (e) {
      window.showingLightbox = false;
  });




  jQuery('#speciesSelect').on('change', function(){
    var val = jQuery(this).val();
    document.location.href = '/flags' + (val != "" ? '?f='+val : '');
  });

//});   // old jquery ready stuff...
}
window.setupStuff();

jQuery(document).ready(function(){
  window.winResize();
});
